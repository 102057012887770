import { FullContainer } from "@/components";

export const HomepageShutdown = () => {
  return (
    <FullContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
          paddingBottom: 50,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <img
          alt="ittybrief icon"
          style={{
            height: "5rem",
            width: "auto",
            objectFit: "contain",
            marginRight: 10,
          }}
          src={require("@/assets/icon-tight.png")}
        />
        <h1>ittybrief end of life</h1>
        <p style={{ maxWidth: 600 }}>
          Thank you for your interest in ittybrief but unfortunately, we have shut down the service. While many enjoyed the personalized
          daily newsletters, the overhead cost of running the service proved to be too great.
        </p>
        <p style={{ maxWidth: 600 }}>
          If you have any questions or would like to purchase the underlying technology powering the recommendation engine, please reach out
          to <a href="mailto:jack@ittybrief.com">jack@ittybrief.com</a>.
        </p>
      </div>
    </FullContainer>
  );
};
